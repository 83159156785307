import { usePrevious } from "@react-hooks-library/core";
import { useMovieActivityContext } from "src/components/film/filmwebid/MovieActivityContext";
import RatingPanel from "src/components/filmwebid/RatingPanel";
import LoadingIndicator from "src/components/loadingindicator/LoadingIndicator";
import HeartSvg from "src/components/svg/HeartSvg";
import SpeechBubbleSvg from "src/components/svg/SpeechBubbleSvg";
import StarSvg from "src/components/svg/StarSvg";
import WatchlistSvg from "src/components/svg/WatchlistSvg";
import theme from "src/components/themes/DefaultTheme";
import { useUserContext } from "src/lib/contexts/UserContext";
import useFavourite from "src/lib/filmwebid/useFavourite";
import useWatchlist from "src/lib/filmwebid/useWatchlist";
import styled from 'src/lib/styles/css';
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

const ReviewPanel = dynamic(() => import('src/components/filmwebid/ReviewPanel'), {
	loading: () => <LoadingIndicator />,
	ssr: false
});

//#region [Props]
type FilmwebIdButtonPanelProps = {
	edi?: string | null;
	streamingId?: string | number;
	reviewsListRef?: React.RefObject<HTMLElement>;
	hideUserInteractionStatus?: boolean;
	hideGiveRating?: boolean;
	hideCommenting?: boolean;
	className?: string;
};
//#endregion

interface IWatchListStatus {
	isWatchlisted: boolean;
	watchlistCount: number;
};

interface IFavouriteStatus {
	isFav: boolean;
	favCount: number;
}

//#region [Component]
export default function FilmwebIdButtonPanel({ edi, streamingId, reviewsListRef, hideUserInteractionStatus = false, className, hideCommenting = false, hideGiveRating = false }: FilmwebIdButtonPanelProps) {
	const userContext = useUserContext();
	const movieActivityContext = useMovieActivityContext();
	const [watchlister, isWatchlisting] = useWatchlist({ edi, streamingId: streamingId ? Number(streamingId) : undefined });
	const [favouriter, isFavving] = useFavourite({ edi, streamingId: streamingId ? Number(streamingId) : undefined });
	const [showRatingPanel, setShowRatingPanel] = useState(false);
	const [showReviewPanel, setShowReviewPanel] = useState(false);

	// Maintain local state here since we want to show that something has changed immediately without wating for saving to complete
	const [watchlistStatus, setWatchlistStatus] = useState<IWatchListStatus>({ isWatchlisted: false, watchlistCount: 0 });
	const [favStatus, setfavStatus] = useState<IFavouriteStatus>({ isFav: false, favCount: 0 });

	const prevEdi = usePrevious(edi);
	const prevStreamingId = usePrevious(streamingId);

	useEffect(() => {
		// reset the hook if the edi or streamingId changes
		if (edi !== prevEdi || streamingId !== prevStreamingId) {
			setfavStatus({ isFav: false, favCount: 0 });
			setWatchlistStatus({ isWatchlisted: false, watchlistCount: 0 });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edi, streamingId]);

	useEffect(() => {
		setfavStatus({
			isFav: !!movieActivityContext.activity?.movieUserInteraction?.favourite,
			favCount: movieActivityContext.activity?.movieStats?.favouriteCount ?? 0
		});
	}, [movieActivityContext.activity?.movieUserInteraction?.favourite, movieActivityContext.activity?.movieStats?.favouriteCount]);

	useEffect(() => {
		setWatchlistStatus({
			isWatchlisted: !!movieActivityContext.activity?.movieUserInteraction?.watchlisted,
			watchlistCount: movieActivityContext.activity?.movieStats?.watchlistCount ?? 0
		});
	}, [movieActivityContext.activity?.movieUserInteraction?.watchlisted, movieActivityContext.activity?.movieStats?.watchlistCount]);

	if (!edi && !streamingId) {
		return null;
	}

	// event handlers (created here for readability)
	const onWatchlistClick = () => {
		setWatchlistStatus(oldState => {
			const newState = {
				isWatchlisted: !oldState.isWatchlisted,
				watchlistCount: oldState.isWatchlisted ? Math.max(0, oldState.watchlistCount - 1) : oldState.watchlistCount + 1
			};
			watchlister(newState.isWatchlisted);
			return newState;
		});
	};

	const onFavClick = () => {
		setfavStatus(oldState => {
			const newState = {
				isFav: !oldState.isFav,
				favCount: oldState.isFav ? Math.max(0, oldState.favCount - 1) : oldState.favCount + 1
			};
			favouriter(newState.isFav);
			return newState;
		});
	};

	const onRatingPanelClick = () => {
		if (userContext.isAuthenticated) {
			setShowRatingPanel(true);
		} else {
			userContext.doLogin();
		}
	}

	const onReviewPanelClick = () => {
		if (userContext.isAuthenticated) {
			setShowReviewPanel(true);
		} else {
			userContext.doLogin();
		}
	}

	const onRatingPanelClose = (doUpdate: boolean) => {
		setShowRatingPanel(false);
		if (doUpdate) {
			movieActivityContext.activityUpdater();
		}
	}

	const onReviewPanelClose = (doUpdate: boolean) => {
		setShowReviewPanel(false);
		if (doUpdate) {
			movieActivityContext.activityUpdater();
		}
	}

	return <SFilmwebIdButtonPanel className={className}>
		<SFilmwebIdButton title="Legg filmen/serien i huskelisten"
			className="watchListButton"
			onClick={onWatchlistClick}
			disabled={movieActivityContext.isLoading || !movieActivityContext.activity}
			aria-label="Legg filmen/serien i huskelisten"
		>
			<WatchlistSvg selected={watchlistStatus.isWatchlisted} />
			{!hideUserInteractionStatus && <SStatus>{watchlistStatus.watchlistCount > 0 ? watchlistStatus.watchlistCount : '\u200B'}</SStatus>}
		</SFilmwebIdButton>
		{!hideGiveRating && <SFilmwebIdButton title="Gi filmen/serien din vurdering"
			onClick={onRatingPanelClick}
			disabled={movieActivityContext.isLoading || !movieActivityContext.activity}
			aria-label="Gi filmen/serien din vurdering"
		>
			<StarSvg selected={!!movieActivityContext.activity?.movieUserInteraction?.rating} />
			{!hideUserInteractionStatus && <SStatus>{(movieActivityContext.activity?.movieStats?.ratingCount ?? 0) > 0 ? `${movieActivityContext.activity?.movieStats?.ratingAverage.toFixed(1)} / 5` : '\u200B'}</SStatus>}
		</SFilmwebIdButton>}

		<SFilmwebIdButton title="Merk filmen/serien som en favoritt"
			onClick={onFavClick}
			className="favButton"
			disabled={movieActivityContext.isLoading || !movieActivityContext.activity}
			aria-label="Merk filmen/serien som en favoritt"
		>
			<HeartSvg selected={favStatus.isFav} />
			{!hideUserInteractionStatus && <SStatus>{favStatus.favCount > 0 ? favStatus.favCount : '\u200B'}</SStatus>}
		</SFilmwebIdButton>

		{!hideCommenting && <SFilmwebIdButton title="Skriv anmeldelse"
			onClick={onReviewPanelClick}
			disabled={movieActivityContext.isLoading || !movieActivityContext.activity}
			aria-label="Skriv anmeldelse"
		>
			<SpeechBubbleSvg selected={!!movieActivityContext.activity?.movieUserInteraction?.review} />
			{!hideUserInteractionStatus && <SStatus>{(movieActivityContext.activity?.movieStats?.reviewCount ?? 0) > 0 ? movieActivityContext.activity?.movieStats?.reviewCount : '\u200B'}</SStatus>}
		</SFilmwebIdButton>}

		{showReviewPanel && <ReviewPanel
			edi={edi}
			streamingId={streamingId ? Number(streamingId) : undefined}
			onClose={onReviewPanelClose}
			reviewId={movieActivityContext.activity?.movieUserInteraction?.reviewId}
			userRating={movieActivityContext.activity?.movieUserInteraction?.rating}
			userReview={movieActivityContext.activity?.movieUserInteraction?.review}
			reviewsListRef={reviewsListRef}
		/>}


		{showRatingPanel && <RatingPanel
			onClose={onRatingPanelClose}
			edi={edi}
			streamingId={streamingId ? Number(streamingId) : undefined}
			hasUserReview={!!movieActivityContext.activity?.movieUserInteraction?.review}
			userRating={movieActivityContext.activity?.movieUserInteraction?.rating}
			movieStats={movieActivityContext.activity?.movieStats} />}

	</SFilmwebIdButtonPanel>;
}
//#endregion

//#region [Styles]
const SFilmwebIdButtonPanel = styled.div`
	margin-top: 15px;
	width: calc(100% - 10px);

	display: flex;
	justify-content: center;
	gap: 13%;
	transition: opacity 0.5s;

	@media ${theme.mq.desktop} {
		grid-area: header;
		order: 2;
		align-self: flex-start;
		max-width: 20%;
		margin-top: 25px;
		margin-left: 20px;

		gap: 0;
		justify-content: space-between;
	}
`;

const SFilmwebIdButton = styled.button`
	flex: 0 0 auto;
	min-width: 42px;
	border: none;
	background: none;
	flex: 0 0 auto;
	opacity: 0.5;
	transition: opacity 0.5s;
	text-align: center;
	color: var(--textcolor);
	vertical-align: top;
	padding: 0;
	margin: 0;

	&:enabled {
		opacity: 1;
	}

	svg {
		height: 26px;
		width: auto;

		@media ${theme.mq.desktop} {
			height: 35px;
		}
	}
`;

const SStatus = styled.span`
	display: block;
`;
//#endregion