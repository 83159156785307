import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import NoProviders from "src/components/streaming/NoProviders";
import StreamingProvider from "src/components/streaming/StreamingProvider";
import StreamingguideLogo from "src/components/streaming/StreamingguideLogo";
import theme from "src/components/themes/DefaultTheme";
import { StreamingProviderContent } from "src/lib/movieinfo/movieinfotypes";
import styled, { css } from 'src/lib/styles/css';
import { uniqBy } from "lodash";
import { useMemo } from "react";

//#region [Props]
type StreamingProviderListProps = {
    className?: string;
    streamingId: number;
    label?: string;
    providers?: StreamingProviderContent[];
    href?: string;
    showLogo?: boolean;
    hideLabel?: boolean;
    sponsor?: React.ReactNode;
};
//#endregion

//#region [Component]
export default function StreamingProviderList({ streamingId, className, providers, href, showLogo, hideLabel, label = "Her kan du streame filmen/serien", sponsor }: StreamingProviderListProps) {
    const providerList = useMemo(() => providers ? uniqBy(providers, (p) => p.provider!.id) : null, [providers]);
    return <SStreamingProviderList className={className}>
        <SHeading>
            {sponsor}
            {href && <SLabelLink to={href}>{label}</SLabelLink>}
            {!href && <SLabelDiv>
                {showLogo && <SSGLogo />}
                {!hideLabel && label && <div>{label}</div>}
            </SLabelDiv>}
        </SHeading>
        {(providerList?.length ?? 0) === 0 && <NoProviders streamingId={streamingId} />}
        {(providerList?.length ?? 0) > 0 && <SProviderList>
            {providerList?.map((provider, pIdx) => <SStreamingProvider key={`${streamingId}_${provider.id}`} providerContent={provider} />)}
        </SProviderList>}
    </SStreamingProviderList>;
}
//#endregion

//#region [Styles]
const SStreamingProviderList = styled.div`
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	margin-bottom: 20px;
`;

const CSSLabel = css`
	color: var(--BODYTEXTCOLOR-OVERRIDE, var(--buttontextcolor)) !important;
	text-decoration: none;
	margin-bottom: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
    flex: 1 1 auto;

	@media ${theme.mq.desktop} {
		flex-direction: row;
		justify-content: space-between;
		.sgLogo {
			max-width: 30%;
			order: 1;

		}
	}
`;

const SLabelLink = styled(FilmwebLink)`
	${CSSLabel}

	&:hover {
		text-decoration: underline;
	}
`;

const SHeading = styled.div`
    @media ${theme.mq.desktop} {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end;
    }
`;

const SLabelDiv = styled.div`
	${CSSLabel}
`;

const SSGLogo = styled(StreamingguideLogo)`
	max-width: 80%;
	width: 100%;
	margin: 0;

	path {
		fill: ${theme.sgLogoColor};
	}

	@media ${theme.mq.desktop} {
		max-width: 316px;
	}
`;

const SProviderList = styled.div`
	overflow-x: hidden;
	display: flex;
	flex-wrap: wrap;

	gap: 20px;
`;

const SStreamingProvider = styled(StreamingProvider)`
	flex: 0 0 auto;
	width: calc(100% / 2 - 10px);

	@media ${theme.mq.desktop} {
		//@media  (orientation: landscape) {
		width: calc(100% / 3 - 40px / 3);
	}
`;
//#endregion