import SelectorWrapper from "src/components/selectorwrapper/SelectorWrapper";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import styled from 'src/lib/styles/css';


//#region [Props]
type LocationSelectorProps = {
	className?: string;
};
//#endregion

type Locations = {
	theaters: string[];
	location: string;
	label: string;
	firmId: number;
}

export const NFK_LOCATIONS: Locations[] = [
	{
		theaters: [
			"Arendal kino"
		],
		location: "Arendal",
		label: "Arendal",
		firmId: 53
	},
	{
		theaters: [
			"Asker kino"
		],
		location: "Asker",
		label: "Asker",
		firmId: 4
	},
	{
		theaters: [
			"Askim kino"
		],
		location: "Askim",
		label: "Askim",
		firmId: 19
	},
	{
		theaters: [
			"Farsund kino"
		],
		location: "Farsund",
		label: "Farsund",
		firmId: 75
	},
	{
		theaters: [
			"KinoCity"
		],
		location: "Drammen",
		label: "Drammen",
		firmId: 14

	},
	{
		theaters: [
			"Kristiansand kino"
		],
		location: "Kristiansand S",
		label: "Kristiansand S",
		firmId: 2
	},
	{
		theaters: [
			"Kristiansund kino"
		],
		location: "Kristiansund",
		label: "Kristiansund",
		firmId: 32
	},
	{
		theaters: [
			"Halden kino"
		],
		location: "Halden",
		label: "Halden",
		firmId: 20
	},
	{
		theaters: [
			"Horten kino"
		],
		location: "Horten",
		label: "Horten",
		firmId: 91
	},
	{
		theaters: [
			"Hønefoss kinosenter"
		],
		location: "Hønefoss",
		label: "Hønefoss",
		firmId: 68
	},
    {
        theaters: [
            "Sarpsborg Kino"
        ],
        location: "Sarpsborg",
        label: "Sarpsborg",
        firmId: 192
    },
	{
		theaters: [
			"Kilden Kino",
		],
		location: "Tønsberg",
		label: "Tønsberg",
		firmId: 109
	},
	{
		theaters: [
			"Lagunen"
		],
		location: "Bergen",
		label: "Bergen",
		firmId: 171

	},
	{
		theaters: [
			"Colosseum",
			"Gimle",
			"Klingenberg",
			"Ringen",
			"Saga",
			"Symra",
			"Vika"


		],
		location: "Oslo",
		label: "Oslo",
		firmId: 3
	},
	{
		theaters: [
			"Verdal kino"
		],
		location: "Verdal",
		label: "Verdal",
		firmId: 37
	}

];


//#region [Component]
export default function NFKLocationSelector({ className }: LocationSelectorProps) {
	const locationContext = useLocationContext();


	return <SelectorWrapper onClose={() => locationContext.setShowLocationDialog(false)} heading="Velg sted">
		<SLocationSelector>
			<SResultArea>
				{NFK_LOCATIONS.map((loc, index) => (
					<SResultItem key={index} onClick={() => {
						locationContext.setLocation(loc!.location);
						locationContext.setShowLocationDialog(false);
					}}>{loc!.label}</SResultItem>
				))}
			</SResultArea>
		</SLocationSelector>
	</SelectorWrapper>;
}
//#endregion

//#region [Styles]
const SLocationSelector = styled.div`
	min-height: 50cqi;
`;

const SResultArea = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const SResultItem = styled.button`
	padding: 10px 15px;
	cursor: pointer;
	font-size: 20px;
	font-weight: bold;

	background: transparent;
	color: var(--TEXTCOLOR_OVERRIDE, var(--textcolor));
	border: none;
`;
//#endregion