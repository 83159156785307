import styled from 'src/lib/styles/css';

//#region [Props]
type AgeRatingProps = {
    reason?: string;
    age?: string;
    recommendedAge?: string;
};
//#endregion

//#region [Component]
export default function AgeRating({ reason, age, recommendedAge }: AgeRatingProps) {
    if (!age) {
        return null;
    }
    return <div>
        <div><span>Aldersgrense {reason ? "med begrunnelse" : ""}:</span> {age} {recommendedAge && <>(passer for {recommendedAge})</>}</div>
        {reason && <div>{reason}</div>}
    </div>
}
//#endregion

//#region [Styles]

//#endregion