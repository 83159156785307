import { Distributor, Genre } from '@filmweb/sanity-types';
import AgeRating from "src/components/filmfacts/AgeRating";
import People from 'src/components/filmfacts/People';
import FilmPremiere from 'src/components/filmpremiere/FilmPremiere';
import theme from 'src/components/themes/DefaultTheme';
import { type StreamingContent } from 'src/lib/movieinfo/movieinfotypes';
import styled from 'src/lib/styles/css';
import { IFilmomtale } from 'src/lib/types/Filmomtale';

//#region [Props]
type FilmFactsProps = {
    className?: string;
    cmsDocument?: IFilmomtale | null;
    streamingData?: StreamingContent | null;
    isStreaming?: boolean;
};
//#endregion

//#region [Component]
export default function FilmFacts({ cmsDocument, streamingData, className, isStreaming = false }: FilmFactsProps) {
    const title = isStreaming ? streamingData?.title : cmsDocument?.title;
    const orgTitle = isStreaming ? streamingData?.originalTitle : cmsDocument?.originalTitle;

    const genres = (isStreaming ? streamingData?.genres : (cmsDocument?.genres as Genre[])?.map(g => g.name))?.join(" / ");

    const showAlternativeActorLabel = !isStreaming ? (cmsDocument?.genres as Genre[])?.some(g => {
        const genreName = g?.name?.toLowerCase() ?? "";
        return genreName === "dokumentar" || genreName === "musikk";
    }) : false;

    const nationality = isStreaming ? streamingData?.nationality?.trim() : cmsDocument?.nationality?.join(", ")?.trim();

    const originalLanguage = isStreaming ? streamingData?.originalLanguage?.trim() : cmsDocument?.originalLanguage?.join(", ")?.trim();

    const year = isStreaming ? streamingData?.year : cmsDocument?.year;

    const distribusjon = (cmsDocument?.distributor as Distributor)?.name;


    return <SFilmFacts className={className}>
        {!isStreaming && <FilmPremiere premiere={cmsDocument?.premiere} />}
        {orgTitle && title !== orgTitle && <div><span>Originaltittel:</span> {orgTitle}</div>}
        <People label={showAlternativeActorLabel ? "Medvirkende" : "Skuespillere"} persons={isStreaming ? streamingData?.actors : cmsDocument?.castV2} />
        {!isStreaming && <People label="Norske stemmer" persons={cmsDocument?.norwegianVoiceCastV2} />}
        {genres && <div><span>Sjanger: </span> {genres}</div>}
        <People label="Regi" persons={isStreaming ? streamingData?.director : cmsDocument?.directorV2} />
        <People label="Manus" skipLink persons={isStreaming ? streamingData?.writers : cmsDocument?.writerV2} />
        {nationality && <div><span>Nasjonalitet:</span> {nationality}</div>}
        {!isStreaming && <AgeRating reason={cmsDocument?.ageRating?.ageReason} age={cmsDocument?.ageRating?.age} recommendedAge={cmsDocument?.ageRating?.recommendedAge} />}
        {originalLanguage && <div><span>Språk:</span> {originalLanguage}</div>}
        {year && <div><span>Produksjonsår:</span> {year}</div>}
        {!isStreaming && distribusjon && <div><span>Distributør:</span> {distribusjon}</div>}
    </SFilmFacts>;
}
//#endregion

//#region [Styles]
const SFilmFacts = styled.div`
	color: var(--buttontextcolor);
	font-size: ${theme.fonts.bodytextS.size};
	line-height: 26px;
	color: var(--FILMFACTINFOCOLOR-OVERRIDE, var(--buttontextcolor));

	@media ${theme.mq.desktop} {
		font-size: ${theme.fonts.bodytextM.size};
		line-height: 30px;
		margin-bottom: 1em;
	}

	span {
		font-weight: bold;
		color: var(--TEXTCOLOR-OVERRIDE, var(--buttontextcolor));
	}

`;
//#endregion
