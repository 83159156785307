"use client"
import { useUserContext } from "src/lib/contexts/UserContext";
import useWatchlist from "src/lib/filmwebid/useWatchlist";
import { useMemo } from "react";

//#region [Props]
type WithWatchlistStateProps = {
	edi?: string | null;
	streamingId?: number | null;
}

//#endregion


//#region [Props]
export interface IWatchlistable {
	isWatchlisted?: boolean;
	onWatchlistClick?: () => void;
}

//#endregion
//#region [Component]
function withWatchlistState<P extends IWatchlistable>(WrappedComponent: React.ComponentType<P>) {

	const EnhancedComponent = (props: WithWatchlistStateProps & Omit<P, "isWatchlisted" | "onWatchlistClick">) => {
		const userContext = useUserContext();
		const [watchlister, isWatchlisting] = useWatchlist({ edi: props.edi, streamingId: props.streamingId });

		const isWatchlisted = useMemo(() => {
			return userContext.watchlistedList.some(item => (
				(props.edi && item.edi && item.edi === props.edi) ||
				(props.streamingId && item.streamingId && item.streamingId === props.streamingId)
			));
		}, [userContext.watchlistedList, props.edi, props.streamingId]);
		return <WrappedComponent  {...props as P} isWatchlisted={isWatchlisted} onWatchlistClick={() => watchlister(!isWatchlisted)} />
	}
	EnhancedComponent.displayName = `WithWatchlistState(${getDisplayName(WrappedComponent)})`;
	return EnhancedComponent;
}

export default withWatchlistState;
//#endregion

//#region [Other]
function getDisplayName<P>(WrappedComponent: React.ComponentType<P>) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
//#endregion
