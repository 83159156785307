import PosterRow from "src/components/posterrow/PosterRow";
import theme from "src/components/themes/DefaultTheme";
import { type StreamingContent } from "src/lib/movieinfo/movieinfotypes";
import styled from 'src/lib/styles/css';


//#region [Props]
type SimilarStreamingTitlesProps = {
	similarContent: StreamingContent[];
	className?: string;
};
//#endregion

//#region [Component]
export default function SimilarStreamingTitles({ similarContent, className }: SimilarStreamingTitlesProps) {
	return <SSimilarStreamingTitles className={className}>
		<SHeading>Lignende titler</SHeading>
		<PosterRow posterList={similarContent} renderPlaceholders max={6}/>
	</SSimilarStreamingTitles>;
}
//#endregion

//#region [Styles]
const SSimilarStreamingTitles = styled.section`
	margin-top: 1rem;
`;

const SHeading = styled.h1`
	margin: 0;
	color: ${theme.textColor};
`;
//#endregion