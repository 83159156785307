"use client"
import LoadingIndicator from 'src/components/loadingindicator/LoadingIndicator';
import theme from 'src/components/themes/DefaultTheme';
import { gql } from 'graphql-request';
import { useFilminfo } from 'src/lib/client/useFilminfo';
import styled from 'src/lib/styles/css';
import { useEffect, useRef, useState } from 'react';

//#region [Props]
type TrailerPlayerProps = {
	trailerId?: number | string;
	streamingTrailer?: string | null;
};
//#endregion

//#region [Component]
export default function TrailerPlayer({ trailerId, streamingTrailer }: TrailerPlayerProps) {
	const trailerEmbedResult = useFilminfo(TRAILER_EMBED_QUERY, { clipId: Number(trailerId), autoplay: true }, { active: !!trailerId });
	// We use our own isLoading in order to control timing of animation.
	// We have a timer to allow animation to finish before the trailer is embedded
	const [isLoading, setIsLoading] = useState(true);

	const timerRef = useRef<number | undefined | null>(undefined);
	useEffect(() => {
		if (trailerId) {
			if (typeof timerRef.current === "undefined") {
				timerRef.current = window?.setTimeout(() => {
					timerRef.current = null;
					// if trailer is loaded BEFORE one second has passed
					if (trailerEmbedResult?.fiData && !trailerEmbedResult?.fiLoading) {
						setIsLoading(false);
					}
				}, 1000);
			}

			// if the trailer loads AFTER one second has passed
			if (timerRef.current === null && trailerEmbedResult?.fiData && !trailerEmbedResult?.fiLoading) {
				setIsLoading(false);
				timerRef.current = null;
			}

			return () => {
				if (timerRef.current) {
					clearTimeout(timerRef.current);
					timerRef.current = undefined;
				}
			}
		}
	}, [trailerEmbedResult, trailerId]);

	useEffect(() => {
		if (streamingTrailer) {
			if (typeof timerRef.current === "undefined") {
				timerRef.current = window?.setTimeout(() => {
					timerRef.current = null;
					setIsLoading(false);
				}, 1000);
			}

			return () => {
				if (timerRef.current) {
					clearTimeout(timerRef.current);
					timerRef.current = undefined;
				}
			}
		}
	}, [streamingTrailer]);



	return <SPlayerContainer>
		{isLoading && <LoadingIndicator size='large' />}
		{!isLoading && <>
			{trailerEmbedResult?.fiData && <div dangerouslySetInnerHTML={{ __html: trailerEmbedResult.fiData?.movieQuery?.getMovieClip?.embedCode ?? "" }}></div>}
			{!trailerId && streamingTrailer &&
				<SYTIframe
					title='Trailer'
					frameBorder="0"
					allow="autoplay; encrypted-media;"
					allowFullScreen
					src={`https://www.youtube.com/embed/${streamingTrailer}?autoplay=1`}
				></SYTIframe>
			}
		</>}

	</SPlayerContainer>;
}
//#endregion

//#region [Styles]
const SPlayerContainer = styled.div`
	margin: 0 auto;
	max-width: ${theme.maxContentWidth}px;
	aspect-ratio: 16 / 9;
`;

const SYTIframe = styled.iframe`
	width: 100%;
	height: 100%;
	display: block;
`;
//#endregion

//#region [Other]
const TRAILER_EMBED_QUERY = gql`
	query ($clipId: Int, $autoplay: Boolean) {
		movieQuery {
			getMovieClip(clipId: $clipId, autoplay: $autoplay) {
				__typename
				embedCode
			}
		}
	}
`;
//#endregion