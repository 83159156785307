"use client"
import { Genre, Premiere } from "@filmweb/sanity-types";
import Button from "src/components/button/Button";
import NoLocation from "src/components/program/NoLocation";
import NoShows from "src/components/film/program/NoShows";
import Show from "src/components/film/program/Show";
import theme from "src/components/themes/DefaultTheme";
import { compareAsc, parseISO } from "date-fns";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import { type Show as ShowType } from "src/lib/movieinfo/movieinfotypes";
import styled, { css } from 'src/lib/styles/css';
import { useEffect, useState } from "react";



//#region [Props]
type ShowsProps = {
	edi: string;
	movieTitle: string;
	premiere?: Premiere;
	genres?: Genre[];
	isKinoklubbMovie?: boolean;
	shows?: ShowType[];
};
//#endregion

//#region [Component]
export default function Shows({ edi, movieTitle, premiere, genres, isKinoklubbMovie, shows }: ShowsProps) {
	const [showRows, setShowRows] = useState(6); // only relevant on mobile where each show is one row
	const locationContext = useLocationContext();
	const cinemaContext = useSelectedCinemaContext();
	const [filteredShows, setFilteredShows] = useState<ShowType[]>([]);

	useEffect(() => {
		if ((shows?.length ?? 0) > 0) {
			let _shows = shows!.filter(show => compareAsc(parseISO(show!.showStart), new Date()) > 0) as ShowType[];
			if ((cinemaContext.selectedCinemas?.length ?? 0) > 0) {
				_shows = _shows.filter(show => cinemaContext!.selectedCinemas!.includes(show!.theaterName!));
			}
			setFilteredShows(_shows);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shows, cinemaContext.selectedCinemas]);

	if (!locationContext?.location) {
		return <NoLocation />;
	}

	const isLandscape = window.matchMedia("(min-width: 550px)").matches;
	const isLargeWidth = window.matchMedia("(min-width: 1200px)").matches;
	const divider = isLargeWidth ? 3 : 2;

	const showAllShows = (isLandscape || showRows >= filteredShows.length);
	const firstSize = Math.floor(filteredShows.length / divider) + ((filteredShows.length % divider > 0) ? 1 : 0);
	const secondSize = Math.floor(filteredShows.length / divider) + ((filteredShows.length % divider > 1) ? 1 : 0);


	return <SShowWrapper>
		{(shows?.length ?? 0) === 0 && <NoShows edi={edi} movieTitle={movieTitle} premiere={premiere} />}
		{(filteredShows.length ?? 0) > 0 && <>
			{filteredShows.slice(0, showAllShows ? filteredShows.length : showRows).map((show, index) => {
				let order = index;
				if (isLandscape) {
					order = getDesktopOrder(firstSize, secondSize, index);
				}
				return <SShow key={`${show.ticketSaleUrl?.trim() ?? index}`}
					$order={order}
					show={show}
					genres={genres}
					isKinoklubbMovie={isKinoklubbMovie}
				/>
			})}
			{!showAllShows && <SShowAllButton onClick={() => setShowRows(filteredShows.length)} text="Se alle forestillinger" />}
		</>}

	</SShowWrapper>;
}
//#endregion

//#region [Other]
function getDesktopOrder(sizeFirst: number, sizeSecond: number, index: number): number {
	if (index < sizeFirst) {
		return (index * 3) + 1;
	}
	else if (index < sizeFirst + sizeSecond) {
		return ((index - sizeFirst) * 3) + 2;
	}
	return ((index - (sizeFirst + sizeSecond)) * 3) + 3;
}
//#endregion

//#region [Styles]
const SShowWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	color: var(--buttoncolor);

	margin-top: 10px;
	position: relative;
    gap: 5px;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
        align-items: stretch;
		flex-direction: row;
        flex-wrap: wrap;
	}
`;

type SShowProps = {
	$order: number;
};

const SShow = styled(Show) <SShowProps>`
	width: 100%;
	order: ${props => props.$order};

	@media ${theme.mq.desktop} {
		flex: 0 0 32%;
		//margin-right: 5px !important;
		//max-width: calc((100% / 2) - 5px) !important;
		// TODO: Order blir helt feil i dette breakpointet
	}
    /*
	@media (min-width: 1200px) {
		max-width: calc((100% / 3) - 5px) !important;
		${props => props.$order > 1 && props.$order % 3 === 0 ? css`
			margin-right: 0 !important;
		`: ""}
	}*/
`;

const SShowAllButton = styled(Button)`
	margin: 10px 0;
	order: 7;

	@media ${theme.mq.desktop} {
		margin: 10px 0 30px 0;
	}
`;
//#endregion
