import type { IMovieActivity } from "src/lib/filmwebid/IMovieActivity";
import useMovieActivity from "src/lib/filmwebid/useMovieActivity";
import React, { useContext } from "react";

export type MovieActivityContextType = {
	activity: IMovieActivity | null | undefined;
	activityUpdater: () => void;
	isLoading: boolean;
}

const MovieActivityContext = React.createContext<MovieActivityContextType>({
	activity: null,
	activityUpdater: () => { },
	isLoading: false
});

//#region [Props]
type MovieActivityWrapperProps = {
	edi?: string | null;
	streamingId?: string | number;
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export function MovieActivityWrapper({ edi, streamingId, children }: MovieActivityWrapperProps) {
	const [activity, activityUpdater, isLoading] = useMovieActivity(edi, streamingId);

	return (<MovieActivityContext.Provider value={{
		activity,
		activityUpdater,
		isLoading
	}}>
		{children}
	</MovieActivityContext.Provider>);
}
//#endregion

//#region [Other]
export function useMovieActivityContext() {
	return useContext(MovieActivityContext);
}
//#endregion

//#region [Styles]

//#endregion